const payPalCheckoutButtonDictIT = {
  unexpectedErrorTransaction: 'Errore imprevisto durante la registrazione.',
  invalidUserPlan: 'Piano utente non valido.',
  emailConfirmationTitle: 'Registrato con successo!',
  emailConfirmationMessage: 'Controlla la tua email per attivare il tuo account.',
  upgradedSuccessfully: "Piano dell'account aggiornato correttamente!",
  upgradedSuccessfullyText: "Sarà necessario inserire nuovamente le credenziali di accesso. Verrai reindirizzato alla schermata di accesso in 20 secondi, oppure puoi premere il pulsante in basso.",
  youWillBeRedirectedIn: 'Verrai reindirizzato a',
  seconds: 'secondi.',
  noStoresFound: 'Nessun negozio trovato.',
  cardNumber: 'Numero di carta',
  expirationDate: 'Data di scadenza',
  securityCode: 'Codice di sicurezza',
}

export default payPalCheckoutButtonDictIT
