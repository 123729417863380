import colorHorizontalLogo from "../../pages/images/logo-color-horizontal.png";
import googleplay_badge from "../../pages/images/pages/googleplay_badge.png";
import appstore_badge from "../../pages/images/pages/appstore_badge.svg";
import { Link } from "react-router-dom";
import localeText from "../../locale";

import LanguageSelectionButton from "../LanguageSelectionButton/LanguageSelectionButton";
import { Button } from "primereact/button";
import "./Navbar.css";
import { useState } from "react";
import { useEffect } from "react";
import GetUserInfoService from "../../service/GetUserInfoService";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import Wrapper from "../Wrapper/Wrapper";
import { useFavoritesContext } from "../../contexts/FavoritesContext/FavoritesContext";
import { useUserContext } from "../../contexts/UserContext/UserContext";

const defaultUserPhoto =
  "https://res.cloudinary.com/dwpu3xryx/image/upload/c_fill,f_auto,g_face,h_80,r_max,w_80/user_avatar_onpaw0.jpg";

const Navbar = (props) => {
  const { setRightSidebarVisible } = props;

  const { favoritesCount } = useFavoritesContext();

  const [loadingUserInfo, setLoadingUserInfo] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [OS, setOS] = useState(false);
  const { userInfo, setUserInfo } = useUserContext();

  const [numberOfCartItems, setNumberOfCartItems] = useState(-1);

  const userInfoService = new GetUserInfoService();

  const toast = useRef(null);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("__extraconomy_ismobile__"))) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) setOS("android");

      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) setOS("ios");
    }
  }, []);

  useEffect(() => {
    let cancel = false;

    async function fetchUserInfo() {
      if (localStorage.getItem("__extraconomy_token")) {
        try {
          setLoadingUserInfo(true);

          const resType = await userInfoService.getUserTypeInfo(
            localStorage.getItem("__extraconomy_username")
          );
          if (cancel) return;

          if (resType.result === "Admin") {
            setIsAdmin(true);
            setLoadingUserInfo(false);
            return;
          }

          const res = await userInfoService.getUserInfo(
            localStorage.getItem("__extraconomy_token"),
            localStorage.getItem("__extraconomy_username")
          );

          if (res.messageCode !== 200001) {
            toast.current.show({
              severity: "error",
              summary: localeText("error"),
              detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
              life: 6000,
            });
            setLoadingUserInfo(false);
          } else {
            setUserInfo(res.result.user);
            setLoadingUserInfo(false);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    fetchUserInfo();

    return () => {
      cancel = true;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("__extraconomy_token"), localStorage.getItem("__extraconomy_username")]);

  // Show number of items in cart
  useEffect(() => {
    if (localStorage.getItem("__extraconomy_order")) {
      const order = JSON.parse(localStorage.getItem("__extraconomy_order"));
      setNumberOfCartItems(order.itens.length);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("__extraconomy_token"), localStorage.getItem("__extraconomy_username")]);

  return (
    <div className="surface-0">
      <Wrapper classnames="flex flex-wrap align-items-center w-full h-10rem surface-0 justify-content-between">
        <Toast ref={toast} />

        <Sidebar
          visible={visible}
          position="right"
          onHide={() => setVisible(false)}
          style={{
            background: "#252529",
          }}
        >
          <ul className="list-none m-0 p-0">
            <li className="flex align-items-center justify-content-center">
              <Link to="/shop" className="a-no-hover mr-0 p-2">
                <i className="pi pi-shopping-bag text-green-600 "></i>
                <span className="ml-2 color-dark-gray font-bold">{localeText("shop")}</span>
              </Link>
            </li>
            <li>
              <Link to="/favorites" className="flex justify-content-center p-2">
                <span className="uppercase flex align-items-center font-medium">
                  <i className="pi pi-heart text-2xl mr-2" style={{ color: "#d32f2f" }}></i>
                  {!!favoritesCount && favoritesCount !== -1 && (
                    <div
                      className="flex align-items-center justify-content-center absolute border-circle text-0 text-sm"
                      style={{
                        background: "#d32f2f",
                        marginLeft: "1rem",
                        marginBottom: "1.3rem",
                        height: "1.25rem",
                        width: "1.25rem",
                      }}
                    >
                      {favoritesCount}
                    </div>
                  )}
                  <span className="button-blank color-dark-gray">{localeText("favorites")}</span>
                </span>
              </Link>
            </li>
            <li>
              <Link to="/cart" className="flex justify-content-center p-2">
                <span className="uppercase flex align-items-center font-medium">
                  <i className="pi pi-shopping-cart text-2xl mr-2" style={{ color: "#f7b005" }}></i>
                  {numberOfCartItems >= 0 && (
                    <div
                      className="flex align-items-center justify-content-center absolute border-circle text-0 text-sm"
                      style={{
                        background: "#f7b005",
                        marginLeft: "1rem",
                        marginBottom: "1.3rem",
                        height: "1.25rem",
                        width: "1.25rem",
                      }}
                    >
                      {numberOfCartItems}
                    </div>
                  )}
                  <span className="button-blank color-dark-gray">{localeText("cart")}</span>
                </span>
              </Link>
            </li>
            <li>
              <a
                href="https://business.extraconomy.com"
                rel="noopener noreferrer"
                className="flex justify-content-center p-2"
              >
                <span className="uppercase flex align-items-center font-medium">
                  <i className="pi pi-home text-2xl mr-2" />
                  <span className="button-blank color-dark-gray">Business</span>
                </span>
              </a>
            </li>
            <li className="my-4"></li>
            <li className="my-4 w-full flex justify-content-center">
              <LanguageSelectionButton />
            </li>
            <li className="my-6">
              <div className="text-center lg:mt-1 lg:ml-0">
                {isAdmin ? (
                  <Link to="/dashboard" className="a-no-hover p-0 ml-1">
                    <Button
                      label="Admin Backoffice"
                      icon="pi pi-server"
                      className="p-button-outlined surface-0"
                    />
                  </Link>
                ) : (
                  <>
                    {userInfo && (
                      <Link to="/dashboard" className="a-no-hover p-0 ml-1">
                        <img
                          className="h-2rem ml-3 surface-0 border-circle"
                          src={userInfo?.urlAvatar ? userInfo.urlAvatar : defaultUserPhoto}
                          alt="Icon do user"
                        ></img>
                      </Link>
                    )}
                    {!userInfo && (
                      <>
                        {loadingUserInfo ? (
                          <i className="pi pi-spin pi-spinner ml-3 text-600 text-xl"></i>
                        ) : (
                          <div>
                            <Link to="/login" className="a-no-hover p-0 xl:ml-1 ml-0">
                              <button type="button" className="p-button -mt-1 ml-0 mb-4">
                                <span className="p-button-text flex align-items-center gap-2 font-bold uppercase">
                                  {localeText("login")}
                                  <i className="pi pi-arrow-right"></i>
                                </span>
                              </button>
                            </Link>

                            <Link to="/specialcustomer" className="a-no-hover p-0 ml-1">
                              <button className="button-blank mobile-a">
                                {localeText("specialCustomer")}
                              </button>
                              {JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) && (
                                <p className="text-white">{localeText("customerDescription")}</p>
                              )}
                            </Link>
                            <Link to="/businesspartner" className="a-no-hover p-0 ml-1">
                              <button className="button-blank mobile-a">
                                {localeText("businessPartner")}
                              </button>
                              {JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) && (
                                <p className="text-white">{localeText("partnerDescription")}</p>
                              )}
                            </Link>
                            <Link to="/areamanager" className="a-no-hover p-0 ml-1">
                              <button className="button-blank mobile-a">
                                {localeText("areaManager")}
                              </button>
                              {JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) && (
                                <p className="text-white">{localeText("managerDescription")}</p>
                              )}
                            </Link>
                            {OS &&
                              !JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) && (
                                <div className="mt-3">
                                  <a
                                    href={
                                      OS === "android"
                                        ? "https://play.google.com/store/apps/details?id=com.extraconomymobile"
                                        : "https://apps.apple.com/app/extraconomy/id6446035801"
                                    }
                                  >
                                    <img
                                      src={OS === "android" ? googleplay_badge : appstore_badge}
                                      width={80}
                                      alt="store"
                                    />
                                  </a>
                                  <p>{localeText("checkApp")}</p>
                                </div>
                              )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </li>
          </ul>
        </Sidebar>

        <div className="flex justify-content-center lg:hidden block">
          <div
            className={`border-1 border-300 surface-0 ml-3 mt-3 mb-4 text-center cursor-pointer border-round ${
              props.hiddenLeftSideBar ? "hidden" : ""
            }`}
            onClick={() => setRightSidebarVisible(true)}
          >
            <i className="pi pi-bars text-4xl p-3"></i>
          </div>
        </div>

        <Link
          to={`${JSON.parse(localStorage.getItem("__extraconomy_ismobile__")) ? "/shop" : "/"}`}
        >
          <img
            src={colorHorizontalLogo}
            alt="Extraconomy logo color"
            className="lg:block hidden"
            height={40}
            style={{ marginLeft: "4rem" }}
          ></img>
          <img
            src={colorHorizontalLogo}
            alt="Extraconomy logo color"
            className="lg:hidden block"
            height={40}
          ></img>
        </Link>

        <div className="lg:flex-wrap lg:flex align-items-center mr-4 hidden">
          <Link to="/favorites" className="mr-2">
            <span className="uppercase flex align-items-center font-medium">
              <i className="pi pi-heart text-2xl mr-2" style={{ color: "#d32f2f" }}></i>
              {!!favoritesCount && favoritesCount !== -1 && (
                <div
                  className="flex align-items-center justify-content-center absolute border-circle text-0 text-sm"
                  style={{
                    background: "#d32f2f",
                    marginLeft: ".9rem",
                    marginBottom: "1.4rem",
                    height: "1.25rem",
                    width: "1.25rem",
                  }}
                >
                  {favoritesCount}
                </div>
              )}
              <span className="button-blank color-dark-gray">{localeText("favorites")}</span>
            </span>
          </Link>

          <Link to="/cart" className="mr-2">
            <span className="uppercase flex align-items-center font-medium">
              <i className="pi pi-shopping-cart text-2xl mr-2" style={{ color: "#f7b005" }}></i>
              {numberOfCartItems >= 0 && (
                <div
                  className="flex align-items-center justify-content-center absolute border-circle text-0 text-sm"
                  style={{
                    background: "#f7b005",
                    marginLeft: ".9rem",
                    marginBottom: "1.4rem",
                    height: "1.25rem",
                    width: "1.25rem",
                  }}
                >
                  {numberOfCartItems}
                </div>
              )}
              <span className="button-blank color-dark-gray">{localeText("cart")}</span>
            </span>
          </Link>

          <a
            href="https://business.extraconomy.com"
            rel="noopener noreferrer"
            className="flex justify-content-center p-2 mr-2"
          >
            <span className="uppercase flex align-items-center font-medium">
              <i className="pi pi-home text-2xl mr-2" />
              <span className="button-blank color-dark-gray">Business</span>
            </span>
          </a>

          <Link to="/shop" className="mr-2">
            <span className="uppercase flex align-items-center font-medium">
              <i className="pi pi-shopping-bag text-2xl mr-2" style={{ color: "#46a748" }}></i>
              <span className="button-blank color-dark-gray">{localeText("shop")}</span>
            </span>
          </Link>

          <div className="mr-2">
            <LanguageSelectionButton />
          </div>

          <div className="text-center lg:mt-1 lg:ml-0">
            {isAdmin ? (
              <Link to="/dashboard" className="a-no-hover p-0 ml-3">
                <Button
                  label="Admin Backoffice"
                  icon="pi pi-server"
                  className="-mt-1 p-button-outlined surface-0"
                />
              </Link>
            ) : (
              <>
                {userInfo && (
                  <Link to="/dashboard" className="a-no-hover p-0 ml-1">
                    <img
                      className="h-2rem ml-3 surface-0 border-circle"
                      src={userInfo?.urlAvatar ? userInfo.urlAvatar : defaultUserPhoto}
                      alt="Icon do user"
                    ></img>
                  </Link>
                )}
                {!userInfo && (
                  <>
                    {loadingUserInfo ? (
                      <i className="pi pi-spin pi-spinner ml-3 text-600 text-xl"></i>
                    ) : (
                      <Link to="/login" className="a-no-hover p-0 xl:ml-1 ml-0">
                        <button type="button" className="p-button -mt-1 ml-0">
                          <span className="p-button-text flex align-items-center gap-2 font-bold uppercase">
                            {localeText("login")}
                            <i className="pi pi-arrow-right"></i>
                          </span>
                        </button>
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>

        <div className="justify-content-between block lg:hidden">
          <div></div>
          <i
            className="pi pi-ellipsis-v mr-4 text-2xl cursor-pointer"
            onClick={() => setVisible(true)}
          ></i>
        </div>
      </Wrapper>
    </div>
  );
};

export default Navbar;
