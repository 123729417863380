import {
  PayPalCardFieldsProvider,
  PayPalCVVField,
  PayPalExpiryField,
  PayPalNameField,
  PayPalNumberField,
  usePayPalCardFields,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import localeText from "../../locale";
import OrderService from "../../service/OrderService";
import { removelocalStorageToken } from "../../utilities/sessionStorage/token";
import { removelocalStorageUsername } from "../../utilities/sessionStorage/username";
import { useHistory } from "react-router-dom";
import IntegrationService from "../../service/IntegrationService";
import GetInfoService from "../../service/GetInfoService";
import AdvancedPayPalService from "../../service/AdvancedPayPalService";
import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { use } from "react";
import paypalImg from "../../pages/images/paypal.png";
import masterCardImg from "../../pages/images/masterCard.png";
import americanExpress from "../../pages/images/expressoAmericano.png";
import visa from "../../pages/images/visa.png";
import PagamentoSeguro from "../../pages/images/pagamentoSeguro.png";

const SubmitPayment = ({ isPaying, setIsPaying, toast }) => {
  const { cardFieldsForm, fields } = usePayPalCardFields();

  async function submitHandler() {
    if (!cardFieldsForm) return; // validate that `submit()` exists before using it
    const formState = await cardFieldsForm.getState();
    if (!formState.isFormValid) {
      toast.current.show({
        severity: "error",
        summary: localeText("error"),
        detail: localeText("invalidCardDetails"),
        life: 6000,
      });
      return;
    }
    setIsPaying(true);
    cardFieldsForm
      .submit()
      .then(() => {
        console.log(fields);
      })
      .catch(() => {
        setIsPaying(false);
        console.log("error");
      });
  }
  return <Button className="w-full" label={localeText("payNow")} loading={isPaying} onClick={submitHandler}></Button>;
};
const PayPalCheckoutCart = (props) => {
  const { address, city, country, district, number, postalCode, state } = props.addressInfo;
  const {
    setPage,
    setDisplayPaymentModal,
    setIdTransaction,
    price,
    priceOriginal,
    toast,
    amountWallet,
    userCurrency,
  } = props;

  const Navigate = useHistory();

  const order = JSON.parse(localStorage.getItem("__extraconomy_order"));

  const orderService = new OrderService();
  const advancedPayPalService = new AdvancedPayPalService();
  const integrationService = new IntegrationService();
  const getUserInfoService = new GetInfoService();

  const orderId = useRef(null);
  const [isPaying, setIsPaying] = useState(false);

  const firstDigit = (num) => {
    const matches = String(num).match(/\d/);
    const digit = Number(matches[0]);
    return num < 0 ? -digit : digit;
  };

  async function sendPurchase() {
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "Purchase",
    });
    const user = await getUserInfoService.getUserInfo(
      localStorage.getItem("__extraconomy_token"),
      localStorage.getItem("__extraconomy_username")
    );
    let ip = await integrationService.getUserIp();
    let date = new Date();
    let payload = {
      eventData: {
        currency: userCurrency,
        value: price,
      },
      eventName: "Purchase",
      eventTime: Math.floor(date.getTime() / 1000),
      userData: {
        email: user.result.user?.email ? user.result.user.email : "",
        ipAddress: ip.data.ip,
        phone: user.result.user?.phoneNumber ? user.result.user.phoneNumber : "",
        userAgent: window.navigator.userAgent,
      },
    };
    let res = await integrationService.sendEvent(payload);
    return res;
  }

  async function validateAndFinish(transactionID) {
    let amountBankTransfer = parseFloat(
      (props.amountBankTransfer ? props.amountBankTransfer : 0).toFixed(2)
    );
    try {
      props.setLoading(true);

      const res = await orderService.validateBeforeFinish(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        address,
        priceOriginal,
        amountWallet ?? 0,
        parseFloat(
          (props?.amountToPayWithExtracredit ? props?.amountToPayWithExtracredit : 0).toFixed(2)
        ),
        amountBankTransfer,
        props?.bankTransferReceipt,
        city,
        country,
        district,
        order.id,
        number,
        postalCode,
        state,
        transactionID
      );

      if (firstDigit(res.messageCode) !== 2) {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
          life: 6000,
        });
        if (firstDigit(res.messageCode) === 5) {
          console.log(res);
        }
        props.setLoading(false);
        return false;
      } else {
        localStorage.removeItem("__extraconomy_order");
        finishOrder(transactionID);
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        removelocalStorageToken();
        removelocalStorageUsername();
        Navigate.push("/login");
      }
      props.setLoading(false);
      console.log(e);
      return false;
    }
  }

  async function finishOrder(transactionID) {
    let amountBankTransfer = parseFloat(
      (props.amountBankTransfer ? props.amountBankTransfer : 0).toFixed(2)
    );
    try {
      props.setLoading(true);

      const res = await orderService.postFinishOrder(
        localStorage.getItem("__extraconomy_token"),
        localStorage.getItem("__extraconomy_username"),
        address,
        priceOriginal,
        amountWallet ?? 0,
        parseFloat(
          (props?.amountToPayWithExtracredit ? props?.amountToPayWithExtracredit : 0).toFixed(2)
        ),
        amountBankTransfer,
        props?.bankTransferReceipt,
        city,
        country,
        district,
        order.id,
        number,
        postalCode,
        state,
        transactionID
      );

      if (res.messageCode !== 200000) {
        toast.current.show({
          severity: "error",
          summary: localeText("error"),
          detail: localeText("data").find((msg) => msg.code === res.messageCode).message,
          life: 6000,
        });
        setPage(4);
        if (res.messageCode === 500000) {
          console.log(res);
          setPage(4);
        }
        props.setLoading(false);
        return false;
      } else {
        localStorage.removeItem("__extraconomy_order");
        sendPurchase();
        setDisplayPaymentModal(false);
        setPage(3);
      }
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        removelocalStorageToken();
        removelocalStorageUsername();
        Navigate.push("/login");
      }
      props.setLoading(false);
      console.log(e);
      return false;
    }
  }

  const createOrder = async () => {
    const payload = {
      amount: price,
      currency: "EUR",
      returnUrl: "https://example.com/success",
      cancelUrl: "https://example.com/cancel",
    };
    try {
      const res = await advancedPayPalService.createOrder(payload);
      orderId.current = res.id;
      return res.id;
    } catch (e) {
      console.log(e);
    }
  };
  const onApprove = async () => {
    try {
      const res = await advancedPayPalService.captureOrder(orderId.current);
      setIsPaying(false);
      setIdTransaction(res.id);
      validateAndFinish(res.id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <PayPalButtons
        disabled={false}
        fundingSource={undefined}
        options={{ currency: "EUR" }}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={(err) => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: localeText("error"),
            detail: localeText("unexpectedErrorTransaction"),
            life: 6000,
          });
        }}
      />
      <div className="flex justify-content-center align-items-center w-full flex-wrap gap-2 mx-auto mt-4">
        <img
          src={paypalImg}
          alt="paypal"
          title="paypal"
          style={{ width: "20px", height: "20px" }}
        />
        <img
          src={americanExpress}
          alt="americanExpress"
          title="americanExpress"
          style={{ width: "20px", height: "20px" }}
        />
        <img
          src={masterCardImg}
          alt="mastercard"
          title="mastercard"
          style={{ width: "30px", height: "20px" }}
        />
        <img src={visa} alt="visa" style={{ width: "35px", height: "15px" }} title="visa" />
      </div>
      <div className="flex justify-content-center align-items-center gap-2 mt-3">
        <p className="m-0 text-xs">{localeText("safePayment")}</p>
        <img
          src={PagamentoSeguro}
          alt=""
          style={{ width: "20px" }}
          title={localeText("safePayment")}
        />
      </div>
      <div className="mt-3">
        <h4 className="text-center text-lg font-semibold">{localeText("creditCardInfo")}</h4>
      </div>
      <PayPalCardFieldsProvider
        options={{ currency: "EUR" }}
        createOrder={createOrder}
        onApprove={onApprove}
        onError={(err) => {
          console.log(err);
          toast.current.show({
            severity: "error",
            summary: localeText("error"),
            detail: localeText("unexpectedErrorTransaction"),
            life: 6000,
          });
        }}
      >
        <PayPalNameField placeholder={localeText("name")} />
        <PayPalNumberField placeholder={localeText("cardNumber")} />
        <PayPalExpiryField placeholder={localeText("expirationDate")} />
        <PayPalCVVField placeholder={localeText("securityCode")} />
        <SubmitPayment toast={toast} isPaying={isPaying} setIsPaying={setIsPaying} />
      </PayPalCardFieldsProvider>
    </>
  );
};

export default PayPalCheckoutCart;
